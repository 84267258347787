@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /* Add !important to override the Emotion defaults on base components */
  button,
  .button,
  input[type="button"],
  input[type="reset"],
  input[type="submit"] {
    @apply tracking-wide !important;
  }

  html,
  body {
    height: 100%;
    width: 100%;
    min-width: 320px;
  }
}

@layer utilities {
  .text-label {
    /* This is duplicated in theme.ts for Chakra */
    @apply tracking-wide font-semibold;
    text-transform: capitalize;
    font-size: 1rem;
    line-height: 1rem;
  }
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.ag-root-wrapper {
  border-radius: 0.5rem;
}

#root {
  height: 100%;
  width: 100%;
}

/* Debugging */
/* * {
  outline: 1px solid rgba(255, 0, 0, 0.2);
  :hover {
    outline: 1px solid rgba(255, 0, 0, 0.6);
  }
}
* * {
  outline: 1px solid rgba(0, 255, 0, 0.2);
  :hover {
    outline: 1px solid rgba(0, 255, 0, 0.6);
  }
}
* * * {
  outline: 1px solid rgba(0, 0, 255, 0.2);
  :hover {
    outline: 1px solid rgba(0, 0, 255, 0.6);
  }
}
* * * * {
  outline: 1px solid rgba(255, 0, 255, 0.2);
  :hover {
    outline: 1px solid rgba(255, 0, 0, 0.6);
  }
}
* * * * * {
  outline: 1px solid rgba(0, 255, 255, 0.2);
  :hover {
    outline: 1px solid rgba(0, 255, 0, 0.6);
  }
}
* * * * * * {
  outline: 1px solid rgba(255, 255, 0, 0.2);
  :hover {
    outline: 1px solid rgba(0, 0, 255, 0.6);
  }
}
* * * * * * * {
  outline: 1px solid rgba(255, 0, 0, 0.2);
  :hover {
    outline: 1px solid rgba(255, 0, 0, 0.6);
  }
}
* * * * * * * * {
  outline: 1px solid rgba(0, 255, 0, 0.2);
  :hover {
    outline: 1px solid rgba(0, 255, 0, 0.6);
  }
}
* * * * * * * * * {
  outline: 1px solid rgba(0, 0, 255, 0.2);
  :hover {
    outline: 1px solid rgba(0, 0, 255, 0.6);
  }
} */

/* Shadcn UI */
@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: hsl(212.7, 26.8%, 83.9);
  }
}

@layer base {
  body {
    @apply bg-background text-foreground !important;
  }
}

@keyframes wait-bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  15%,
  35%,
  55% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  25%,
  45%,
  75% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
  65%,
  85%,
  95% {
    transform: translateY(0);
  }
}

.animate-wait-bounce {
  animation: wait-bounce 3s infinite;
}
